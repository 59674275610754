<template>
    <b-card title="Create Applied Equipment Warranty">
        <b-col cols="12">
            <label>Project Name <span style="color:red">(*)</span></label>
            <b-form-input placeholder="Applied Equipment Warranty Name" v-model="equipment.name"></b-form-input>
        </b-col>
        <b-col cols="12">
            <label>Address <span style="color:red">(*)</span></label>
            <b-form-input placeholder="Applied Equipment Warranty Address" v-model="equipment.address"></b-form-input>
        </b-col>
        <b-col cols="12">
            <label>Postal <span style="color:red">(*)</span></label>
            <b-form-input placeholder="Applied Equipment Warranty Postal Code" v-model="equipment.postalCode"></b-form-input>
        </b-col>
        <b-col cols="12">
            <label>Country <span style="color:red">(*)</span></label>
            <b-form-input list="list-country" placeholder="Applied Country" v-model="equipment.country"></b-form-input>
            <b-form-datalist id="list-country" :options="equipmentCountry"></b-form-datalist>
        </b-col>

        <b-col cols="12">
            <label>Applied Customer <span style="color:red">(*)</span></label>
            <b-form-input v-model="equipment.developer" placeholder="Applied Customer Name" ></b-form-input>
        </b-col>

        <!-- <b-col cols="12">
            <label>Address 1 <span style="color:red">(*)</span></label>
            <b-form-input v-model="equipment.devAddress1" placeholder="Developer Address Line 1" ></b-form-input>
        </b-col>

        <b-col cols="12">
            <label>Address 2</label>
            <b-form-input v-model="equipment.devAddress2" placeholder="Developer Address Line 2" ></b-form-input>
        </b-col>

        <b-col cols="12">
            <label>Developer Postal <span style="color:red">(*)</span></label>
            <b-form-input placeholder="Developer Postal Code" v-model="equipment.devPostalCode"></b-form-input>
        </b-col>
        <b-col cols="12">
            <label>Developer Country <span style="color:red">(*)</span></label>
            <b-form-input list="list-developer-country" placeholder="Developer Country" v-model="equipment.devCountry"></b-form-input>
            <b-form-datalist id="list-developer-country" :options="devEquipmentCountry"></b-form-datalist>
        </b-col> -->

        

        <b-col cols="12">
            <label>Warranty Duration <span style="color:red">(*)</span></label>
            <b-form-select v-model="equipment.warrantyDuration" :options="warrantyDurations"></b-form-select>
        </b-col>

        <b-col cols="12">
            <label>Email <span style="color:red">(*)</span></label>
            <b-form-input placeholder="Applied Equipment Warranty Email" type="email" v-model="equipment.email"></b-form-input>
        </b-col>
        
        <b-col cols="12">
            <b-row class="hide-on-mobile">
            <!-- <b-col cols="6">
                <label>Start Date <span style="color:red">(*)</span></label>
                <b-form-datepicker v-model="equipment.startDate" class="mb-2"></b-form-datepicker>
            </b-col> -->

            <b-col cols="12">
                <label>Commissioning Date <span style="color:red">(*)</span></label>
                <b-form-datepicker v-model="equipment.topDate" class="mb-2"></b-form-datepicker>
            </b-col>
            </b-row>
            <b-row class="hide-on-desktop">
            <!-- <b-col cols="12">
                <label>Start Date <span style="color:red">(*)</span></label>
                <b-form-datepicker v-model="equipment.startDate" class="mb-2"></b-form-datepicker>
            </b-col> -->

            <b-col cols="12">
                <label>Commissioning Date <span style="color:red">(*)</span></label>
                <b-form-datepicker v-model="equipment.topDate" class="mb-2"></b-form-datepicker>
            </b-col>
            </b-row>
        </b-col>
        <!-- <b-col></b-col> -->

        <!-- <b-col cols="12">
            <label>Sales Order</label>
            <v-select @change="fillDo" v-model="selectedSaleOrder" :options="paginated"
                :filterable="false"
                @open="onOpen"
                @close="onClose"
                @search="(query) => (search = query)">
            <template #list-footer>
                <li v-show="hasNextPage" ref="load" class="loader">
                Loading more options...
                </li>
            </template>
            </v-select>
        </b-col> -->

        

        <b-col cols="12">
            <b-row class="hide-on-mobile">
            <b-col cols="9">
            <label>Sales Order Number<span style="color:red">(*)</span></label>
            <b-form-input  v-model="salesOrder" placeholder="Input Sales Order" ></b-form-input>
            </b-col>
            <b-col cols="1">
                <b-spinner  class="mt-2" v-show="loadingSO" label="Loading..."></b-spinner>
            </b-col>
            <b-col cols="2">
                <b-button class="mt-2" variant="primary" @click="searchSONumber">Add</b-button>
            </b-col>
            </b-row>
            <b-row class="hide-on-desktop">
            <b-col cols="12">
            <label>Sales Order Number<span style="color:red">(*)</span></label>
            <b-form-input  v-model="salesOrder" placeholder="Input Sales Order" ></b-form-input>
            </b-col>
            <b-col cols="2">
                <b-button class="mt-2" variant="primary" @click="searchSONumber">Add</b-button>
            </b-col>
            <b-col cols="1">
                <b-spinner  class="mt-3" v-show="loadingSO" label="Loading..."></b-spinner>
            </b-col>
            </b-row>
        </b-col>

        <b-col cols="12" class="my-3" v-if="doList.length > 0">
          <b-row><b-col>
            <h3>Delivery Order List</h3>
          </b-col></b-row>
            <b-table
                :items="doList"
                :fields="listDOFields"
                show-empty
                :current-page="currentPage"
                :perPage="5"
            >
              <template #cell(status)="row">
                   <span :style="{color:eligibleStatusDO(row.item.status)?'orange':'none'}">{{ statusDO(row.item.status) }}</span> 
                </template>
                <template #cell(action)="row">
                    <b-button variant="primary" size="sm" :disabled="eligibleStatusDO(row.item.status)" @click="fillDo(row.item.sapDo)" class="mr-1">
                  <b-icon icon="plus-square"></b-icon>
                </b-button>
                </template>
            </b-table>
            <br>
            <b-col cols="12">
              <b-pagination
                :total-rows="rows"
                :per-page="5"
                v-model="currentPage"
                style="float: right"
              />
        </b-col>
        </b-col>

        <b-col cols="12" class="my-2">
          <b-row><b-col>
            <h3>Selected Delivery Order List</h3>
          </b-col></b-row>
            <b-table
                :items="equipment.selectedDo"
                :fields="doFields"
                show-empty
            >
                <template #cell(customer)="row">
                    {{ row.item.CustomerName1 }} {{ row.item.CustomerName2 }}
                </template>
                <template #cell(action)="row">
                    <b-button variant="danger" size="sm"  @click="removeItem(row.item.id)" class="mr-1">
                  <b-icon icon="trash"></b-icon>
                </b-button>

                </template>
            </b-table>
        </b-col>

        <b-col cols="12" class="mt-2">
            <b-button variant="success" @click="doSaveEquipment" :disabled="loading">Save</b-button>
            <b-spinner v-show="loading" label="Loading..."></b-spinner>
        </b-col>
    </b-card>
</template>

<script>
import { mapActions } from 'vuex';
import { sleep }from '@/utils/utils.js'
export default {
    data() {
        return {   
            loading: false,
            loadingDO: false,
            loadingSO: false,       
            delivOrder:'',
            salesOrder:'',
            currentPage:1,
            equipment: {
                name: '',
                address: '',
                email: '',
                developer: null,
                saleOrder: null,
                topDate: null,
                warrantyDuration: null,
                postalCode:'',
                country: '',
                // devAddress1:'',
                // devAddress2:'',
                // devPostalCode:'',
                // devCountry:'',
                selectedDo:[]
            },
            warrantyDurations: [
                { value: 12, text: '12 Months' },
                // { value: 15, text: '15 Months' },
                { value: 18, text: '18 Months' }
            ],
            doFields: [
                { key: 'sapDo', label: 'DO Number' },
                { key: 'sapSo', label: 'SO Number' },
                { key: 'dealerNo', label: 'Dealer Code' },
                { key: 'action', label: 'Action' },                
            ],
            listDOFields: [
                { key: 'sapDo', label: 'DO Number' },
                { key: 'sapSo', label: 'SO Number' },
                { key: 'dealerNo', label: 'Dealer Code' },
                { key: 'status', label: 'Status' },
                { key: 'action', label: 'Action' },                
            ],
            equipmentCountry: ["Singapore"],
            devEquipmentCountry: ["Singapore"],
            selectedSaleOrder: null,
            doList: [],
        }
    },
    mounted() {
    },
    computed: {
      rows() {
        return this.doList.length
      },
      
    },
    methods: {
        ...mapActions({
            saveEquipment: 'equipmentWarranty/saveEquipment',
            verifyDo: 'projectWarranty/verifyDo',
            searchSO: 'projectWarranty/searchSO'
        }),
        removeItem(index) {
        this.equipment.selectedDo.splice(index, 1);
    },
    statusDO (status){
        const eligible = ['Fulfilled','Collected','Return Completed']
        let find = eligible.find(x => x === status)
        if(find){
          return find
        }else{
          return 'Not Eligible'
        }
    },
    eligibleStatusDO(item){
        const eligible = ['Fulfilled','Collected','Return Completed']
        if(eligible.includes(item)){
          return false
        }else{
          return true
        }
    },
    searchSONumber(){
      this.loadingDO=true
      console.log(this.salesOrder)
      this.searchSO({saleOrder:this.salesOrder}).then((res) => {  
        console.log({res})
        this.doList = res    
        this.$bvToast.toast('Sales Order Found', {
                title: "Success",
                variant: "success",
                solid: true,
            });
        this.loadingDO=false
        
        this.salesOrder = ''
        }).catch((e) =>{
            console.log({e})
            this.$bvToast.toast(e.response.data.errors[0].message, {
                    title: "Failed",
                    variant: "danger",
                    solid: true,
                });
            this.loadingDO=false
            this.salesOrder=''
        })
    },

    fillDo(doNumber) {
      this.loadingDO=true

      const sameDO= this.equipment.selectedDo.some(x => x.sapDo=== doNumber)
      console.log({sameDO})
      if(sameDO) {
      this.$bvToast.toast("Cannot add same DO Number", {
                  title: "DO number has been added to the list ",
                  variant: "danger",
                  solid: true,
              });
          
      }
      if(!sameDO) {
        this.verifyDo({sapDo:doNumber}).then((res) => {
              // console.log(res)
              this.equipment.selectedDo.push({
                  sapDo:res.sapDo,
                  sapSo:res.sapSo,
                  pcode:res.pcode,
                  dealerNo:res.dealerNo
                  })


              this.$bvToast.toast('Delivery Order Add Successfully', {
                      title: "Success",
                      variant: "success",
                      solid: true,
                  });
              this.loadingDO=false

          }).catch((e) =>{
              console.log({e})
              this.$bvToast.toast(e.response.data.errors[0].message, {
                      title: "Failed",
                      variant: "danger",
                      solid: true,
                  });
                  this.loadingDO=false
          })
      }            
        },
        doSaveEquipment() {
            if (this.loading) {
                return;
            }
            this.loading=true
            this.saveEquipment(this.equipment).then(async (data) => {
                
                this.$bvToast.toast('Applied Equipment Warranty has been successfully registered', {
                        title: "Failed",
                        variant: "success",
                        solid: true,
                    });
                await sleep(1500)
                this.loading=false
                // this.$router.push({ name: "equipment-warranty" });
                this.$router.go(-1)
            }).catch((e) => {
                console.log({e})
                this.$bvToast.toast(e.response.data.errors[0].message, {
                        title: "Failed",
                        variant: "danger",
                        solid: true,
                    });
                this.loading=false
            })
        },
        
    }
}

</script>
<style scoped>

@media (max-width : 761px){
  .hide-on-mobile {display: none !important;}
}
@media (min-width : 762px){
  .hide-on-desktop {display: none !important;}
}
</style>
